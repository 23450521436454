import React, { useEffect, useState, useCallback, useMemo } from "react";
import Footer from "../HomePage/Footer/Footer";
import GetApp from "../HomePage/Get App/GetApp";
import NavBar from "../HomePage/NavBar/NavBar";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import "./Book.css";
import { GetCartData, handleClearStore } from "../Redux/Actions/room_actions";
import { GetCurrencyData } from "../Redux/Actions/currency_actions";
import { PaystackButton } from "react-paystack";
import axios from "../utilities/axios";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import Spinner from "./Spinner";
import BookingModal from "./BookingModal";
import { BookingLoader } from "../utilities/Loader";
import BookingDetails from "./BookingDetails";
import { CalculateTax, convertAmount } from "../utilities/GeneralFunctions";
// import { connect } from 'react-redux';

const Book = ({ handleClearStore }) => {
  const dateFormat = "YYYY-MM-DD";
  const storedDates = JSON.parse(localStorage.getItem("dateRange"));
  let history = useLocation();
  const bookingDetails = GetCartData();

  // console.log("bookingDetails", bookingDetails);

  const navigation = useNavigate();

  let { days, selectedInfo, dateArr } = history.state;

  const totalRoomPriceSum = useMemo(() => {
    return bookingDetails.reduce((sum, item) => {
      const price = item?.hotelHasFlatRate
        ? item.newKey
        : item.totalRoomPrice
        ? item.totalCost
        : item.newKey;

      return sum + price * item.qty + (item.actualBreakfastCost || 0);
    }, 0);
  }, [bookingDetails]);

  const totalAmount = totalRoomPriceSum * days;

  // console.log("totalAmount33", totalAmount)

  const [showDiv, setShowDiv] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [gender, setGender] = useState("");
  const [guestTitle, setTitle] = useState("");
  const [email, setEmail] = useState("");
  const [tiptopgoID, setTiptopgoID] = useState();
  const [address, setAddress] = useState("");
  const [fieldDisable, setFieldDisable] = useState(false);
  const [guestID, setGuestID] = useState();
  const [showPayBtn, setShowPayBtn] = useState();
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [showPayBtn3, setShowPayBtn3] = useState();
  const [discountBtn, setDiscountBtn] = useState(false);
  const [discountCode, setDiscountCode] = useState();
  const [discountApply, setDiscountApply] = useState();
  const [useDiscount, setUseDiscount] = useState(false);
  const [discountAmountDisplay, setDiscountAmountDisplay] = useState();
  const [discountCodeField, setDiscountCodeField] = useState("");
  const [discountCodeZero, setDiscountCodeZero] = useState("");
  const [showMessage, setShowMessage] = useState("");
  const [discountprice, setDiscountPrice] = useState();
  const [showQuantityText, setShowQuantityText] = useState(false);
  const [emptyField, setEmptyField] = useState(false);
  const [doomybtn, setDoomyBtn] = useState(false);
  const [bookingDetails2, setBookingDetails] = useState([]);
  const [disablePayatHotel, setDisablePayatHotel] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [payAtHotelWarning, setPayAtHotelWarning] = useState(0);
  const [totalBillOnDiscountVat, setTotalBillOnDiscountVat] = useState(0);
  const [showPaymentDiv, setShowPaymentDiv] = useState(false);
  const [payAtHotel, setPayAtHotel] = useState(false);
  let [bookArr, setBookArr] = useState([]);
  const [totalRoomCostNoVat, setTotalRoomCostNoVat] = useState(0);
  const [combinedTaxArray, setCombinedTaxArray] = useState([]);
  const [comTaxArrayNoDiscount, setComTaxArrayNoDisCount] = useState([]);
  const { hotelId, hotelName } = useParams();
  const getSelectedCurrency = GetCurrencyData();
  const selectedCurrencyObject = getSelectedCurrency.selectedCurrency;

  const { currencySymbol, currencyRate, currencyCode } = selectedCurrencyObject;

  const goBacktoRoomSelection = () => {
    navigation(-1); // Navigates back to the previous page
  };

  let showBreakFastLab = false;

  let checkAvailableRoom = false;
  let discountAmount;

  let paymentArr = [];

  const [showForm, setShowForm] = useState(false);

  let allValue;

  let payref;

  // console.log("totalAmount", totalAmount );

  let historyData = history.state;

  let discountCode2;

  const mainAmount = totalAmount;

  const hotelIdCheck = historyData.selectedInfo.hotelId;

  const handleCloseModal = () => {
    setShowMessageModal(false);
  };

  useEffect(() => {
    if (
      historyData.selectedInfo.allowRooms === "true" &&
      historyData.selectedInfo.allowPayAtHotel === "true" &&
      historyData.selectedInfo.allowPayNow === "true"
    ) {
      setShowPayBtn(true);
      // setGenPayBtn(true)
    } else {
      setShowPayBtn(false);
      // setGenPayBtn(false)
    }

    if (historyData.selectedInfo.allowPayNow === "true") {
      setShowPayBtn3(true);
      // setGenPayBtn(true)
    } else {
      setShowPayBtn3(false);
      // setGenPayBtn(false)
    }

    if (historyData.selectedInfo.allowPayAtHotel === "true") {
      setShowPayBtn(true);
      // setGenPayBtn(true)
    } else {
      setShowPayBtn(false);
      // setGenPayBtn(false)
    }

    const getDiscount = async () => {
      const discountCode = await axios.get(
        `https://ipms.intellims.com:5300/api/v1/registrations/hotelPromoCodes/${hotelId}`
      );
      if (discountCode.data.length < 1) {
        setDiscountBtn(true);
      } else {
        setDiscountCode(discountCode.data);
        discountCode2 = discountCode.data;
      }

      const currentDate = new Date();
      const formattedDateTime = moment(currentDate).format(
        "YYYY-MM-DD, h:mm:ss"
      );

      if (discountCode2 && discountCode2[0].startDate <= formattedDateTime) {
        setShowMessage(false);
        setDiscountBtn(false);
      } else {
        setDiscountBtn(true);
        setShowMessage(true);
      }

      if (discountCode2 && discountCode2[0].endDate >= formattedDateTime) {
        setShowMessage(false);
        setDiscountBtn(false);
      } else {
        setDiscountBtn(true);
        setShowMessage(true);
      }

      if (discountCodeField === "") {
        setEmptyField(true);
      } else {
        setEmptyField(false);
      }
    };
    getDiscount();
  }, [historyData.selectedInfo]);

  let code = discountCode && discountCode[0].discountCode;

  const handleDiscountFieldChange = (event) => {
    setDiscountCodeField(event.target.value);
    if (discountCodeField !== "") {
      setEmptyField(false);
    } else {
      setEmptyField(true);
    }
  };

  useEffect(() => {
    const checkField = () => {
      if (code === discountCodeField) {
        setDiscountBtn(false);
      } else {
        setDiscountBtn(true);
      }
    };
    checkField();
  }, [discountCodeField]);

  let discount = 0;

  const applyDiscount = (e) => {
    e.preventDefault();
    setDisablePayatHotel(true);

    if (discountCode && discountCode[0].discountCode === discountCodeField) {
      discount = discountCode[0].discountAmount;
      setDiscountPrice(discount && discount);
    }
    let discountArr = [];
    let calDiscountAmount = discount * days;
    // console.log("calDiscountAmount", bookingDetails[0].qty )
    for (const book of bookingDetails2) {
      const discountpriceinArr = discount ? discount * days * book.qty : 0;
      discountArr.push({
        ...book,
        quantity: book.qty,
        discountPrice: discountpriceinArr,
      });
    }

    setBookingDetails(discountArr);

    let sumCheckDiscount = 0;

    discountArr.forEach((item) => {
      sumCheckDiscount += item.discountPrice;
    });

    discountAmount = sumCheckDiscount;
    setDiscountAmountDisplay(discountAmount && discountAmount);
    let mainAmount = totalAmount - discountAmount;

    const absoluteNumber = Math.abs(mainAmount);

    if (mainAmount <= 0) {
      setDiscountApply(0);
      setUseDiscount(true);
      setDiscountCodeZero(true);
    } else {
      setDiscountCodeZero(false);
      setDiscountApply(absoluteNumber);
      setUseDiscount(true);
    }

    const discountObj = {
      paymentType: "Manager Discount",
      amount: discount,
      bankName: "Select a Bank",
      transactionId: "",
      managerName: discountCode[0].createdBy,
      referenceNumber: discountCode[0].discountCode,
      creditId: "",
    };

    // setNewArr(newObj)
    paymentArr.push(discountObj);
    localStorage.setItem("discountObj", JSON.stringify(discountObj));

    setDiscountBtn(true);
  };

  const handleRemoveDiscount = () => {
    let mainAmount = totalAmount;
    setDiscountApply(mainAmount);
    setUseDiscount(false);
    setDiscountCodeField("");
    localStorage.removeItem("discountObj");
    setDisablePayatHotel(false);
  };

  const toggleShow = () => {
    setShowForm(!showForm);
  };

  const getEmail = (e) => {
    setEmail(e.target.value);
  };

  const getFirstName = (e) => {
    setFirstName(e.target.value);
  };

  const getLastName = (e) => {
    setLastName(e.target.value);
  };

  const getTittle = (e) => {
    setTitle(e.target.value);
  };

  const getPhoneNo = (e) => {
    setPhoneNo(e.target.value.replace(/\+/g, ""));
  };

  const getGender = (e) => {
    setGender(e.target.value);
  };

  const getAddress = (e) => {
    setAddress(e.target.value);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isEmailValid = validateEmail(email);
  const isButtonDisabled = !isEmailValid;

  const validatePhoneNo = (phoneNo) => {
    const phoneNoRegex = /^[0-9]+$/;
    return phoneNoRegex.test(phoneNo);
  };

  const isPhoneValid = validatePhoneNo(phoneNo);
  const isButtonDisabled2 = !isPhoneValid;

  const isFieldsEmpty = () => {
    return (
      !firstName ||
      !lastName ||
      !phoneNo ||
      !gender ||
      !email ||
      !address ||
      isButtonDisabled ||
      !guestTitle ||
      isButtonDisabled2
      // || convertAmount() === 0
    );
    //  || disablePayatHotel;
  };
  const getFlateDiscountAmount = totalRoomCostNoVat - +totalAmount;

  // console.log(
  //   "getFlateDiscountAmount",
  //   getFlateDiscountAmount,
  //   totalRoomCostNoVat,
  //   totalAmount
  // );

  useEffect(() => {
    const calCulateBreakFast = () => {
      const bookArray = [];
      let totalTaxAmounts = {};
      let totalTaxAmountsNoDiscount = {};
      let totalBillOnDiscountVat = 0;
      let totalBillOnVat = 0;
      let totalRoomCostNoVat = 0;

      for (const book of bookingDetails && bookingDetails) {
        let amountForRoom = book.totalCostNoDiscount;
        let showBreakFastLab = false;
        let totalBreakfastCost = 0;

        totalRoomCostNoVat += amountForRoom;

        if (book.numOfBreakfast > 0) {
          showBreakFastLab = true;
          totalBreakfastCost =
            book.breakfastFlatCost * book.numOfBreakfast * days * book.qty;
        }

        const {
          totalTaxRoomCat,
          taxDetails,
          totalTaxRoomCatNoDiscount,
          taxDetailsNoDiscount,
        } = CalculateTax(book, days);

        const roomCost = book.newKey * book.qty * days;

        const roomTotalBill = roomCost + totalBreakfastCost + totalTaxRoomCat;
        totalBillOnDiscountVat += roomTotalBill;

        const roomCostNoDiscount = book.roomCost * book.qty * days;

        const roomTotalBillNoDiscount =
          roomCostNoDiscount + totalBreakfastCost + totalTaxRoomCatNoDiscount;

        totalBillOnVat += roomTotalBillNoDiscount;
        // Loop through taxDetails for the current room
        taxDetails.forEach((taxDetail) => {
          const taxLabel = taxDetail.taxLabel;
          const amount = taxDetail.taxAmount || 0;
          const percentage = taxDetail.taxPercentage;
          const flatAmount = taxDetail.flatAmount;

          // Accumulate the amounts for the same tax label
          totalTaxAmounts[taxLabel] = totalTaxAmounts[taxLabel] || {
            amount: 0,
            details: {},
          };

          totalTaxAmounts[taxLabel].amount += amount;

          // Store the corresponding tax details (percentage or flatAmount)
          if (percentage !== null) {
            totalTaxAmounts[taxLabel].details = {
              value: percentage,
              type: "percentage",
            };
          } else if (flatAmount !== null) {
            totalTaxAmounts[taxLabel].details = {
              value: flatAmount,
              type: "flatAmount",
            };
          }
        });

        taxDetailsNoDiscount.forEach((taxDetail) => {
          const taxLabel = taxDetail.taxLabel;
          const amount = taxDetail.taxAmount || 0;
          const percentage = taxDetail.taxPercentage;
          const flatAmount = taxDetail.flatAmount;

          // Accumulate the amounts for the same tax label
          totalTaxAmountsNoDiscount[taxLabel] = totalTaxAmountsNoDiscount[
            taxLabel
          ] || {
            amount: 0,
            details: {},
          };

          totalTaxAmountsNoDiscount[taxLabel].amount += amount;

          // Store the corresponding tax details (percentage or flatAmount)
          if (percentage !== null) {
            totalTaxAmountsNoDiscount[taxLabel].details = {
              value: percentage,
              type: "percentage",
            };
          } else if (flatAmount !== null) {
            totalTaxAmountsNoDiscount[taxLabel].details = {
              value: flatAmount,
              type: "flatAmount",
            };
          }
        });
        const totalTaxArray = Object.entries(totalTaxAmounts).map(
          ([taxLabel, { amount, details }]) => ({
            taxLabel,
            amount: Math.round(amount),
            percentageOrFlatAmount: details.value,
            type: details.type,
          })
        );

        const totalTaxArrayNoDiss = Object.entries(
          totalTaxAmountsNoDiscount
        ).map(([taxLabel, { amount, details }]) => ({
          taxLabel,
          amount: Math.round(amount),
          percentageOrFlatAmount: details.value,
          type: details.type,
        }));

        setCombinedTaxArray(totalTaxArray);
        setComTaxArrayNoDisCount(totalTaxArrayNoDiss);

        bookArray.push({
          ...book,
          totalBreakfastCost: totalBreakfastCost || 0,
          quantity: book.qty,
          showBreakfastLabel: showBreakFastLab,
          totalTaxRoomCat,
          taxDetails: payAtHotel ? taxDetailsNoDiscount : taxDetails,
          totalRoomCostNoVat,
        });
      }
      setPayAtHotelWarning(totalBillOnVat);
      setTotalBillOnDiscountVat(Math.ceil(totalBillOnDiscountVat));
      setBookArr(bookArray);
      setTotalRoomCostNoVat(totalRoomCostNoVat);
    };

    calCulateBreakFast();
  }, [bookingDetails, days, payAtHotel]);

  let HandleChange = (e) => {
    setShowDiv(!showDiv);
  };

  const userDetails = JSON.parse(localStorage.getItem("userData"));
  const logginStat = localStorage.getItem("loggInStatus");

  useEffect(() => {
    const goback = () => {
      if (bookingDetails.length < 1) {
        navigation("/", {});
      }
    };
    goback();
  }, []);

  useEffect(() => {
    setBookingDetails(bookingDetails);
    if (logginStat) {
      setEmail(userDetails.emailAddress);
      setFirstName(userDetails.firstName);
      setLastName(userDetails.lastName);
      setTitle(userDetails.guestTitle);
      setPhoneNo(userDetails.phoneNumber.replace(/\+/g, ""));
      setGender(userDetails.gender);
      setTiptopgoID(userDetails.tipTopGoGuestId);
      setGuestID(userDetails.guestId);
      setAddress(userDetails.address);
    }

    setDoomyBtn(true);

    localStorage.removeItem("discountObj");
  }, []);

  const getAllDetails = async (reference) => {
    payref = reference.reference;

    const paymentObj = {
      paymentType: "cash",
      amount: useDiscount ? discountApply : totalBillOnDiscountVat,
      bankName: "Select a Bank",
      transactionId: "",
      managerName: "",
      referenceNumber: "",
      creditId: "",
    };

    if (useDiscount === true) {
      const localObj = JSON.parse(localStorage.getItem("discountObj"));
      paymentArr.push(localObj, paymentObj);
    } else {
      paymentArr.push(paymentObj);
    }
    const formatedCheckinDate = moment(historyData.dateArr[0]).format(
      "YYYY-MM-DD"
    );
    const formatedCheckOutDate = moment(historyData.dateArr[1]).format(
      "YYYY-MM-DD"
    );
    // console.log("historyData.dateArr[0]", formatedCheckinDate);

    // console.log("historyData.dateArr[1]", formatedCheckOutDate);

    const parent = {
      checkInDate: `${formatedCheckinDate} 15:00:00`,
      checkOutDate: `${formatedCheckOutDate} 13:00:00`,
      hotelId: historyData.selectedInfo.hotelId,
      hasTaxLevies: combinedTaxArray.length > 0 ? true : false,
      numOfDays: days,
      guestId: guestID ? userDetails.guestId : null,
      hotelName: historyData.selectedInfo.hotelName, //"ogidi",
      includeBreakfast: "1 breakfast",
      reservationType: "booked",
      bookingId: "qMOuBxyiSPXXkwGOKVNmHn8z",
      totalBill: useDiscount ? discountApply : totalBillOnDiscountVat,
      paymentType: "paystack",
      paymentReference: payref,
      bookingStatus: "unprocessed",
      referralName: "TiptopgoWeb",
      sourceType: "TiptopgoWeb",
      showBreakfastLabel: false,
      breakfastCost: 0,
      payingGuest: {
        guestTitle: guestTitle ? guestTitle : userDetails.guestTitle,
        gender: gender ? gender : userDetails.gender,
        firstName: firstName ? firstName : userDetails.firstName,
        lastName: lastName ? lastName : userDetails.lastName,
        country: "nigeria",
        phoneNumber: phoneNo ? phoneNo : userDetails.phoneNumber,
        email: email ? email : userDetails.emailAddress,
        address: address ? address : userDetails.address,
        tipTopGoGuestId: tiptopgoID ? userDetails.tipTopGoGuestId : null,
        guestId: guestID ? userDetails.guestId : null,
      },
      bookings: bookArr,
      paymentDetails: paymentArr,
    };

    allValue = parent;

    setLoadingButton(false);
  };

  const getAllDetailsReserved = async (reference) => {
    setLoadingButton(true);
    setPayAtHotel(true);
    payref = reference.reference;

    const paymentObj = {
      paymentType: "cash",
      amount: 0,
      bankName: "Select a Bank",
      transactionId: "",
      managerName: "",
      referenceNumber: "",
      creditId: "",
    };

    if (useDiscount === true) {
      const localObj = JSON.parse(localStorage.getItem("discountObj"));
      paymentArr.push(localObj, paymentObj);
    } else {
      paymentArr.push(paymentObj);
    }

    const formatedCheckinDate = moment(historyData.dateArr[0]).format(
      "YYYY-MM-DD"
    );
    const formatedCheckOutDate = moment(historyData.dateArr[1]).format(
      "YYYY-MM-DD"
    );

    const parent = {
      checkInDate: `${formatedCheckinDate} 15:00:00`,
      checkOutDate: `${formatedCheckOutDate} 13:00:00`,
      hotelId: historyData.selectedInfo.hotelId,
      hasTaxLevies: combinedTaxArray.length > 0 ? true : false,
      numOfDays: days,
      guestId: guestID ? userDetails.guestId : null,
      hotelName: historyData.selectedInfo.hotelName,
      includeBreakfast: "1 breakfast",
      reservationType: "reserved",
      bookingId: "qMOuBxyiSPXXkwGOKVNmHn8V",
      totalAmountToPay:
        payAtHotelWarning <= 0 ? totalAmount : payAtHotelWarning,
      totalBill: 0,
      paymentType: "paystack",
      // paymentReference: payref,
      paymentReference: null,
      bookingStatus: "unprocessed",
      referralName: "TiptopgoWeb",
      sourceType: "TiptopgoWeb",
      showBreakfastLabel: false,
      breakfastCost: 0,
      payingGuest: {
        guestTitle: guestTitle ? guestTitle : userDetails.guestTitle,
        // gender:gender,
        gender: gender ? gender : userDetails.gender,
        // guestTitle: "mr",
        // gender: "male",
        firstName: firstName ? firstName : userDetails.firstName,
        lastName: lastName ? lastName : userDetails.lastName,
        country: "Nigeria",
        phoneNumber: phoneNo ? phoneNo : userDetails.phoneNumber,
        email: email ? email : userDetails.emailAddress,
        address: address ? address : userDetails.address,
        tipTopGoGuestId: tiptopgoID ? userDetails.tipTopGoGuestId : null,
        guestId: guestID ? userDetails.guestId : null,
      },
      bookings: bookArr,
      // 24000 - 2000
      paymentDetails: paymentArr,
    };

    isFieldsEmpty();

    allValue = parent;
    await axios
      .post("/guests/makeWebReservation", allValue)
      .then((res) => {
        notify(res);
        handleClearStore();
        // console.log("booking success", res);
      })
      .catch((err) => {
        console.log("booking error", err);
      });

    setLoadingButton(false);
  };

  const handleTotalBill = async (reference) => {
    setLoadingButton(true);
    payref = reference.reference;
    const paymentObj = {
      paymentType: "cash",
      amount: useDiscount ? discountApply : totalBillOnDiscountVat,
      bankName: "Select a Bank",
      transactionId: "",
      managerName: "",
      referenceNumber: "",
      creditId: "",
    };

    if (useDiscount === true) {
      const localObj = JSON.parse(localStorage.getItem("discountObj"));
      paymentArr.push(localObj, paymentObj);
    } else {
      paymentArr.push(paymentObj);
    }

    const formatedCheckinDate = moment(historyData.dateArr[0]).format(
      "YYYY-MM-DD"
    );
    const formatedCheckOutDate = moment(historyData.dateArr[1]).format(
      "YYYY-MM-DD"
    );

    const parent = {
      checkInDate: `${formatedCheckinDate} 15:00:00`,
      checkOutDate: `${formatedCheckOutDate} 13:00:00`,
      hotelId: historyData.selectedInfo.hotelId,
      numOfDays: days,
      guestId: guestID ? userDetails.guestId : null,
      hotelName: historyData.selectedInfo.hotelName, //"ogidi",
      includeBreakfast: "1 breakfast",
      reservationType: "reserved",
      bookingId: "qMOuBxyiSPXXkwGOKVNmHn8V",
      totalAmount: totalBillOnDiscountVat,
      totalBill: useDiscount ? discountApply : totalBillOnDiscountVat,
      paymentType: "paystack",
      // paymentReference: payref,
      paymentReference: null,
      bookingStatus: "unprocessed",
      referralName: "TiptopgoWeb",
      sourceType: "TiptopgoWeb",
      showBreakfastLabel: false,
      breakfastCost: 0,
      payingGuest: {
        guestTitle: guestTitle ? guestTitle : userDetails.guestTitle,
        gender: gender ? gender : userDetails.gender,
        firstName: firstName ? firstName : userDetails.firstName,
        lastName: lastName ? lastName : userDetails.lastName,
        country: "Nigeria",
        phoneNumber: phoneNo ? phoneNo : userDetails.phoneNumber,
        email: email ? email : userDetails.emailAddress,
        address: address ? address : userDetails.address,
        tipTopGoGuestId: tiptopgoID ? userDetails.tipTopGoGuestId : null,
        guestId: guestID ? userDetails.guestId : null,
      },
      bookings: bookArr,
      paymentDetails: paymentArr,
    };

    allValue = parent;
    // console.log("allvalue", allValue);
    await axios
      .post("/guests/makeWebReservation", allValue)
      .then((res) => {
        notify(res);
        handleClearStore();
        // localStorage.removeItem("dateRange");
        // console.log("booking successfull", res);
      })
      .catch((err) => {
        console.log("booking not successfull", err);
      });

    setLoadingButton(false);
  };

  // console.log("payAtHotel", payAtHotel);

  const notify = (res) => {
    toast.success(res.data.message, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

    let bookCode = res.data.bookingCode;
    setTimeout(() => {
      navigation(
        `/book/booking-summary/${historyData.selectedInfo.hotelName}`,
        {
          state: {
            allValue,
            bookCode,
            combinedTaxArray,
            comTaxArrayNoDiscount,
            totalBillOnDiscountVat,
            discountAmountDisplay,
            mainAmount: totalBillOnDiscountVat,
            payAtHotelWarning,
            totalAmount,
            address: selectedInfo.address,
            hotelPicture: historyData.selectedInfo.picture,
            totalRoomCostNoVat,
            getFlateDiscountAmount,
            payAtHotel,
            storedDates,
          },
        }
      );
    }, 1000);

    setLoadingButton(false);
  };

  const notSuccessFul = () => {
    toast.error("your booking is not successfull", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const handlePaystackSuccessAction = async (reference) => {
    getAllDetails(reference);
    setLoadingButton(true);
    // console.log("allValue", allValue);
    await axios
      .post("/guests/makeWebReservation", allValue)
      .then((res) => {
        notify(res);
        handleClearStore();
        // localStorage.removeItem("dateRange");
        // console.log("booking success", res);
      })
      .catch((err) => {
        console.log("booking error");
      });

    localStorage.removeItem("discountObj");
  };

  const handlePaystackCloseAction = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    // setEmail('')
    //allValue = " ";
  };

  // console.log("totalBillOnDiscountVat", totalBillOnDiscountVat);

  const config = {
    reference: new Date().getTime().toString(),
    email: email,
    amount: useDiscount ? discountApply * 100 : totalBillOnDiscountVat * 100,
    currency: currencyCode,
    // publicKey: "pk_test_573cca83eaa8a37b2ac10bd98015fde5b4fe4df3",
    // publicKey: "pk_test_1a009b53638d9550b7b51fcab8ef964d1d9fa171", // personal key
    publicKey: "pk_live_b3304b8274925a6dd5479dc6972a3ce6adde2396",
  };

  const componentProps = {
    ...config,
    text: "Pay Now",
    onSuccess: (reference) => {
      handlePaystackSuccessAction(reference);
    },
    onClose: handlePaystackCloseAction,
  };

  const setUserData = (e) => {
    e.preventDefault();
    localStorage.setItem("historyData", JSON.stringify(historyData));
    navigation("/Login", { state: {} });
  };

  const checkLogin = () => {
    const isLogin = localStorage.getItem("loggInStatus");
    if (isLogin === "true") {
      setShowForm(true);
      setFieldDisable(true);
    }
  };

  useEffect(() => {
    checkLogin();
  }, []);

  // console.log("historyData", historyData.dateArr);

  const getAvailableRoomsBtn = async () => {
    setShowLoader(true);
    const getAvailableRooms = await axios.get(
      `/hotels/${hotelIdCheck}/roomAvailabilitySummary?startDate=${historyData.dateArr[0]} 15:00:00&endDate=${historyData.dateArr[1]} 13:00:00&sourceType=TiptopgoWeb`
    );
    // setAvailableRooms(getAvailableRooms.data)

    for (const userRoom of bookingDetails2) {
      const availableRoom =
        getAvailableRooms.data &&
        getAvailableRooms.data.find(
          (room) => +room.roomTypeId === +userRoom.roomTypeId
        );
      // console.log("availableRoom", availableRoom)
      if (!availableRoom) {
        checkAvailableRoom = false;
      } else if (userRoom.qty > availableRoom.availableRooms) {
        checkAvailableRoom = false;
      } else {
        checkAvailableRoom = true;
      }
    }

    if (checkAvailableRoom === true) {
      setShowPaymentDiv(true);
      setShowLoader(false);
      setShowQuantityText(false);
      setShowMessageModal(false);
    } else {
      setShowMessageModal(true);
      setShowLoader(false);
      setShowPaymentDiv(false);
      setShowQuantityText(true);
    }
  };

  return (
    <>
      {loadingButton && <Spinner />}
      <NavBar />

      <div className='parent_book'>
        <h2 className='top_instruction'>
          Complete your booking at {historyData.selectedInfo.hotelName} - it
          only takes 30 seconds
        </h2>

        <div className='parent_book_form'>
          <div className='choseOpt'>
            <button
              onClick={toggleShow}
              className={
                showForm ? "show2" : "pay_hotel_button pay_hotel_button1"
              }
            >
              Continue as Guest
            </button>
            <Link to='/Login'>
              <button
                onClick={setUserData}
                className={showForm ? "show2" : "logintoBook"}
              >
                Signin to Continue
              </button>
            </Link>

            <span
              className={showForm ? "show2" : "continueSelection"}
              onClick={goBacktoRoomSelection}
            >
              continue room selection
            </span>
          </div>

          {showForm && (
            <form
              onSubmit={(e) => e.preventDefault()}
              className='book_form_details'
            >
              <h4>Your Details</h4>
              <div className='book__form_'>
                <div>
                  <label for='guestTitle'>
                    Title<span class='required-field'>*</span>
                  </label>
                  <select
                    placeholder='Title'
                    className='first_row book_form_input'
                    onChange={getTittle}
                    value={guestTitle}
                    disabled={fieldDisable}
                    required
                  >
                    <option disabled value=''>
                      {" "}
                      select your title
                    </option>
                    <option id='Mr' value='Mr'>
                      Mr
                    </option>
                    <option id='Mrs' value='Mrs'>
                      Mrs
                    </option>
                    <option id='Miss' value='Miss'>
                      Miss
                    </option>
                    <option id='Chief' value='Chief'>
                      Chief
                    </option>
                  </select>
                </div>

                <div>
                  <label for='name'>
                    Gender<span class='required-field'>*</span>
                  </label>
                  {/* <input type="text" placeholder="country" className="first_row book_form_input"  onChange={getCountry} value={country} /> */}
                  <select
                    placeholder='Gender'
                    className='first_row book_form_input'
                    onChange={getGender}
                    value={gender.trimStart()}
                    disabled={fieldDisable}
                    required
                  >
                    <option disabled value=''>
                      {" "}
                      select your gender
                    </option>
                    <option id='male' value='male'>
                      male
                    </option>
                    <option id='female' value='Female'>
                      Female
                    </option>
                  </select>
                </div>

                {/* <input type="text" placeholder="Mr" className="first_row book_form_input" onChange={getTittle}  value={title} /> */}
                <div>
                  <label for='name'>
                    First Name<span class='required-field'>*</span>
                  </label>
                  <input
                    type='text'
                    placeholder='First Name '
                    className='book_form_input'
                    value={firstName.trimStart()}
                    onChange={getFirstName}
                    disabled={fieldDisable}
                    required
                  />
                </div>
                <div>
                  <label for='name'>
                    Last Name<span class='required-field'>*</span>
                  </label>
                  <input
                    type='text'
                    placeholder='Last Name'
                    className='book_form_input'
                    onChange={getLastName}
                    value={lastName.trimStart()}
                    disabled={fieldDisable}
                    required
                  />
                </div>

                <div>
                  <label for='name'>
                    Phone Number<span class='required-field'>*</span>
                  </label>
                  <input
                    type='text'
                    placeholder='234'
                    className=' book_form_input'
                    onChange={getPhoneNo}
                    value={phoneNo.trim().replace(/\+/g, "")}
                    disabled={fieldDisable}
                    required
                  />
                </div>

                <div>
                  <label for='name'>
                    Email Address<span class='required-field'>*</span>
                  </label>
                  <input
                    type='text'
                    placeholder='john@gmail.com'
                    className='book_form_input'
                    onChange={getEmail}
                    value={email.trimStart()}
                    disabled={fieldDisable}
                    required
                  />
                </div>
              </div>
              {/* <div className="checkbox">
            <input type="checkbox" />
            <p>I'm making this reservation on behalf of someone else</p>
          </div> */}
              <div className='address'>
                <label for='name'>
                  Address<span class='required-field'>*</span>
                </label>
                <textarea
                  cols='10'
                  rows='2'
                  className='text__area book_form_input'
                  placeholder='Address'
                  onChange={getAddress}
                  value={address.trimStart()}
                  disabled={fieldDisable}
                  required
                />
              </div>
              {/* <input type="checkbox"/> */}
              <button className='discount' onClick={HandleChange}>
                {showDiv ? (
                  <span onClick={handleRemoveDiscount}>Remove Discount</span>
                ) : (
                  "Add discount code"
                )}
              </button>{" "}
              {showDiv && (
                <div className='discount_hiden_div'>
                  {showMessage ? (
                    <span className='discountMessage'>
                      oops!! sorry, the discount period have not started yet
                    </span>
                  ) : (
                    <>
                      <input
                        type='text'
                        value={discountCodeField.trim()}
                        onChange={handleDiscountFieldChange}
                        placeholder='Enter code'
                      />
                      {emptyField ? (
                        <button className='disableBtn2'>Apply Discount</button>
                      ) : (
                        <button
                          onClick={applyDiscount}
                          disabled={discountBtn ? true : false}
                          className={
                            discountBtn ? "disableBtn2" : "notDisableBtn"
                          }
                        >
                          Apply Discount
                        </button>
                      )}
                    </>
                  )}
                </div>
              )}
              <br />
              {payAtHotelWarning <= 0 ? null : (
                <div className='payAtHotelWarning'>
                  <span>
                    <span className='important'>Important!!: </span> By clicking
                    the Pay at Hotel payment option, you will be charged{" "}
                    <span className='important'>
                      {currencySymbol || "₦"}
                      {convertAmount(
                        payAtHotelWarning,
                        currencyRate,
                        currencyCode
                      ).toLocaleString()}
                    </span>{" "}
                    for this reservation at the hotel. The flat discount is not
                    applicable when paying at the hotel. If you have any
                    questions or concerns, feel free to reach out to our
                    customer support for clarification on{" "}
                    <span style={{ color: "whitesmoke" }}>
                      (+234 08149069961 or +234 07047208665)
                    </span>{" "}
                    . We value your trust and want to ensure transparency in all
                    financial transactions
                  </span>
                </div>
              )}
              {/* {
                genPayBtn ? <> */}
              {showMessageModal ? (
                <BookingModal closeModal={handleCloseModal} />
              ) : null}
              {showPaymentDiv ? (
                <div className='payOption'>
                  <>
                    {showPayBtn ? (
                      <button
                        // className="pay_hotel_button pay_at_hotel"
                        onClick={getAllDetailsReserved}
                        disabled={isFieldsEmpty()}
                        className={
                          isFieldsEmpty() === true
                            ? "notAllow"
                            : "pay_hotel_button pay_at_hotel"
                        }
                      >
                        Pay at Hotel
                      </button>
                    ) : null}
                    {discountCodeZero ? (
                      <button
                        className='pay_hotel_button pay_hotel_button2 '
                        onClick={handleTotalBill}
                        disabled={isFieldsEmpty()}
                      >
                        Pay Now
                      </button>
                    ) : showPayBtn3 ? (
                      isFieldsEmpty() ? (
                        <button
                          type='submit'
                          className='pay_hotel_button pay_hotel_button2 '
                          style={{
                            cursor: "not-allowed",
                            backgroundColor: "#B7B7CC",
                            border: "none",
                          }}
                          disabled={isButtonDisabled}
                        >
                          Pay Now
                        </button>
                      ) : (
                        <>
                          <PaystackButton
                            className='pay_hotel_button pay_hotel_button2'
                            {...componentProps}
                            // disabled={isFieldsEmpty()}
                          />
                        </>
                      )
                    ) : null}
                  </>
                </div>
              ) : (
                <div className='checkRoomAvailableDiv'>
                  <span
                    className='continueSelection'
                    onClick={goBacktoRoomSelection}
                  >
                    continue room selection
                  </span>
                  <button type='button' onClick={() => getAvailableRoomsBtn()}>
                    Proceed to Booking
                  </button>
                  {showLoader && <BookingLoader />}
                </div>
              )}
              {/* {
              disablePayatHotel ? <span className='discountmass'>Note: When a discount is applied, the "pay at hotel" option cannot be utilized.</span> : null
            } */}
              {/* <div className="privacy_text">
                <p>
                  We use your personal data to process services that you have
                  applied for, to contact you with newsletters and deal offers,
                  and for personalised content and ads. You consent to our Data
                  Policy if you click the above. You can withdraw consent and
                  contact our Data Protection Officer at any time.
                </p>
              </div> */}
            </form>
          )}
          <div className='book_form_room_list'>
            <div className='book_hotel__details'>
              <div className='book_hotel_img'>
                <img src={historyData.selectedInfo.picture} alt='hotel' />
              </div>

              <h3>{historyData.selectedInfo.hotelName} </h3>
            </div>

            <div className='book_hotel__details2'>
              <h4>Booking Summary</h4>
            </div>

            <BookingDetails
              days={days}
              bookingDetails={bookingDetails}
              bookingDetails2={bookingDetails2}
              useDiscount={useDiscount}
              historyData={historyData}
              payAtHotelWarning={payAtHotelWarning}
            />

            {useDiscount && (
              <div className='book_view_more'>
                <span className='spanDis'>
                  <span>Total Amount</span>{" "}
                  <span>
                    {currencySymbol || "₦"}{" "}
                    {convertAmount(
                      totalAmount,
                      currencyRate,
                      currencyCode
                    ).toLocaleString()}{" "}
                  </span>{" "}
                </span>
              </div>
            )}

            <div className='book_view_more_with_tax subTotal'>
              <span className='spanDis'>
                <span>Sub Total Amount</span>{" "}
                <span>
                  {currencySymbol || "₦"}{" "}
                  {convertAmount(
                    totalRoomCostNoVat,
                    currencyRate,
                    currencyCode
                  ).toLocaleString()}
                </span>{" "}
              </span>
            </div>

            <div className='book_view_more_with_tax'>
              <span className='spanDis'>
                <span>Pay Now Discount</span>{" "}
                <span>
                  {currencySymbol || "₦"}{" "}
                  {convertAmount(
                    discountAmountDisplay
                      ? discountAmountDisplay
                      : getFlateDiscountAmount,
                    currencyRate,
                    currencyCode
                  ).toLocaleString()}
                </span>{" "}
              </span>
            </div>

            <div className='book_view_more_with_tax'>
              <span className='spanDis'>
                <span>Total Amount</span>{" "}
                <span>
                  {currencySymbol || "₦"}{" "}
                  {convertAmount(
                    totalAmount,
                    currencyRate,
                    currencyCode
                  ).toLocaleString()}
                </span>{" "}
              </span>
            </div>

            {combinedTaxArray.length > 0 && (
              <div className='tax-container'>
                {combinedTaxArray &&
                  combinedTaxArray.map((taxObject, i) => (
                    <div className='tax-item'>
                      <span>
                        {taxObject.taxLabel}({taxObject.percentageOrFlatAmount}
                        {taxObject.type === "flatAmount" ? "" : "%"})
                      </span>
                      <div className='taxAmount'>
                        <span className='taxindiviAmount'>
                          {currencySymbol || "₦"}{" "}
                          {taxObject.amount.toLocaleString()}
                        </span>
                      </div>
                    </div>
                  ))}
              </div>
            )}

            <div className='book_total'>
              <div className='book_total_det'>
                <h2>Ground Total:</h2>
                <h2>
                  {currencySymbol || "₦"}{" "}
                  {useDiscount
                    ? convertAmount(
                        discountApply,
                        currencyRate,
                        currencyCode
                      ).toLocaleString()
                    : convertAmount(
                        totalBillOnDiscountVat,
                        currencyRate,
                        currencyCode
                      ).toLocaleString()}
                  .00
                </h2>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
      {/* <Receipt historyData={historyData} refer={payref} name={firstName} name2={lastName}/> */}
      <GetApp />
      <Footer />
    </>
  );
};

// const mapStateToProps = (state) => {
//   //  console.log("state",state.cart);
//   const cart = state.cart;
//   return cart;
// };

export default connect(null, { handleClearStore })(Book);
